.App {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.selector {
  min-width: 200px;
}

.panel {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  padding: 0px;

  vertical-align: top;
}

.panel img{
  width: 100%
}
